import { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
  animate,
  m,
  domAnimation,
  LazyMotion,
  MotionValue,
  useMotionValue,
  useTransform,
  useVelocity,
} from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import config from '@helpers/config';
import { Props, PollOption, SubmittedState } from './Vote.types';
import { ColorTextEnum } from '@store/types';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import Button from '@common/Button/Button';
import Section from '@design/Section/Section';
import styles from './Vote.module.scss';

export default function Vote(props: Props) {
  const { locale } = useRouter();
  const {
    button,
    eyebrow,
    versus,
    preTitle,
    preSubtitle,
    selectedTitle,
    postSubtitle,
    tiedPostTitle,
    tiedPostSubtitle,
    voteButton,
  } = props;

  const submit = props.submit ?? submitVote;

  const [isInViewport, setIsInViewport] = useState(false);
  const onEnterViewport = () => {
    setIsInViewport(true);
  };

  const [checkedItem, setCheckedItem] = useState<number | null>(null);

  const [submittedState, setSubmittedState] = useState<SubmittedState>({
    type: 'START',
  });

  // the voteOffset is an amount between -50 and 50 representing the distance
  // of the current voting position from an even 50/50 split. For example,
  // 25%/75% would be an offset of 25, and 80%/20% would be an offset of -30.
  const voteOffset = useMotionValue(0);

  const voteLoadingAnimationRunner = useVoteLoadingAnimationRunner(voteOffset);
  useEffect(() => {
    if (submittedState.type === 'LOADING') {
      // it doesn't matter if we run this more than once,
      // since it only has an effect the first time
      voteLoadingAnimationRunner.runLoadingAnimation();
    }
  }, [submittedState, voteLoadingAnimationRunner]);

  useEffect(() => {
    return () => {
      voteLoadingAnimationRunner.destroy();
    };
  }, [voteLoadingAnimationRunner]);

  // the full length of the background is actually twice the apparent length,
  // so we divide the offset in half (and reverse it, since more points on the
  // right actually translate the background more to the left)
  const backgroundTranslation = useTransform(
    voteOffset,
    offset => `${-offset / 2}%`
  );

  const backgroundTranslationAR = useTransform(
    voteOffset,
    offset => `${offset / 2}%`
  );

  // on desktop, we animate the entire foreground as one big element,
  // with some additional translations on the sub-elements.
  const foregroundTranslation = useTransform(
    voteOffset,
    offset => `${-offset}%`
  );

  const foregroundTranslationAR = useTransform(
    voteOffset,
    offset => `${offset}%`
  );

  // the bags move with the panels, but we additionally translate them
  // relative to the velocity of the moving panels, so they appear to
  // "pull" the panels back and forth
  const backgroundTranslationVelocity = useVelocity(voteOffset);
  const translateBags = useTransform(
    backgroundTranslationVelocity,
    velocity => `${velocity / -5}px`
  );

  // since the background can push very far to one end or the other,
  // we translate the percentages *against* the direction of the panels
  // a bit, so they move with the background panels, just less extremely
  const choicePercentOffset = useTransform(
    voteOffset,
    offset => `${offset / 1.5}vw`
  );

  const choicePercentOffsetAR = useTransform(
    voteOffset,
    offset => `${-offset / 1.5}vw`
  );

  // these two values don't represent styles/transforms - they're the
  // actual vote percentage numbers that get rendered as <MotionValueText>
  const leftVoteNumber = useTransform(voteOffset, offset =>
    Math.ceil(50 - offset)
  );
  const rightVoteNumber = useTransform(voteOffset, offset =>
    Math.floor(50 + offset)
  );

  // handle incomplete data from CMS
  if (props.items === undefined)
    return <BuilderEditingHelper componentName="Vote" visible={true} />;

  const [leftItem, rightItem] = props.items;

  const leftColor = leftItem?.color as unknown as keyof typeof ColorTextEnum;
  const leftBackgroundColor = ColorTextEnum[leftColor];

  const rightColor = rightItem?.color as unknown as keyof typeof ColorTextEnum;
  const rightBackgroundColor = ColorTextEnum[rightColor];

  if (leftItem !== undefined && rightItem !== undefined) {
    leftItem.id = props.createPoll?.values?.pollOptionOneId;
    rightItem.id = props.createPoll?.values?.pollOptionTwoId;
  }

  const vote = async (choice: number, locale: string | undefined) => {
    const pollId = props.createPoll.values.pollId;

    if (submittedState.type !== 'START') return;

    const submissionPromise = submit(pollId, choice, locale);

    setSubmittedState({ type: 'LOADING' });

    const result = await submissionPromise;

    try {
      await voteLoadingAnimationRunner.finishLoadingAnimation(
        50 - result.percentages.left
      );

      setSubmittedState({
        type: 'VOTED',
        result:
          result.percentages.left === result.percentages.right
            ? 0
            : result.percentages.left > result.percentages.right
            ? leftItem.id
            : rightItem.id,
      });
    } catch (cancelled) {}
  };

  const getWinningItem = () => {
    if (submittedState.type === 'VOTED') {
      return submittedState.result === 0
        ? null
        : submittedState.result === leftItem.id
        ? leftItem
        : rightItem;
    }
    return null;
  };

  let outerClassname: string;
  if (submittedState.type === 'START') {
    outerClassname = styles.startState;
  } else if (submittedState.type === 'LOADING') {
    outerClassname = styles.loadingState;
  } else {
    outerClassname = styles.votedState;
  }

  let bottomContent: JSX.Element;
  if (submittedState.type === 'START') {
    let subtitle = preSubtitle;
    if (leftItem !== undefined && rightItem !== undefined) {
      if (checkedItem === leftItem.id) {
        subtitle = leftItem.selectionText;
      } else if (checkedItem === rightItem.id) {
        subtitle = rightItem.selectionText;
      }
    }

    bottomContent = (
      <>
        <h2 className={styles.descriptionTitle}>
          {checkedItem === null ? preTitle : selectedTitle}
        </h2>

        {subtitle && (
          <div
            className={styles.descriptionSubtitle}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}

        {voteButton && (
          <Button
            as="button"
            disabled={!checkedItem}
            design="round"
            className={styles.submitButton}
            onClick={() => {
              if (checkedItem) {
                vote(checkedItem, locale);
              }
            }}
          >
            {voteButton}
          </Button>
        )}
      </>
    );
  } else if (submittedState.type === 'LOADING') {
    bottomContent = <LoadingSpinner />;
  } else {
    bottomContent = (
      <>
        {/* read winner to screen readers */}
        {getWinningItem() ? (
          <span className={styles.srOnly}>{getWinningItem()?.name} wins</span>
        ) : (
          ''
        )}
        <h2 className={styles.descriptionTitle}>
          {getWinningItem()?.resultsText || tiedPostTitle}
        </h2>
        <p className={styles.descriptionSubtitle}>
          {getWinningItem() ? postSubtitle : tiedPostSubtitle}
        </p>
        {button && button?.href && button?.text && (
          <Button
            as="Link"
            href={button?.href}
            design={button?.design}
            className={styles.button}
          >
            {button?.text}
          </Button>
        )}
      </>
    );
  }

  return (
    <Section
      className={clsx(
        styles.voteSection,
        outerClassname,
        isInViewport ? styles.isInViewport : ''
      )}
      viewportThreshold={0.01}
      onEnterViewport={onEnterViewport}
    >
      <div className={styles.choiceBackgroundsWrapper}>
        <LazyMotion features={domAnimation}>
          <div className={styles.choiceBackgroundsContainer}>
            <m.div
              style={{
                // @ts-ignore
                '--translate-background':
                  locale === 'ar'
                    ? backgroundTranslationAR
                    : backgroundTranslation,
                '--left-color':
                  locale === 'ar'
                    ? rightBackgroundColor
                    : leftBackgroundColor || '#151515',
                '--right-color':
                  locale === 'ar'
                    ? leftBackgroundColor
                    : rightBackgroundColor || '#151515',
              }}
            />
          </div>
          <fieldset>
            <legend className={styles.choiceTitleText}>{eyebrow}</legend>
            <m.div
              className={styles.choiceBoxesContainer}
              style={{
                // @ts-ignore
                '--translate-foreground':
                  locale === 'ar'
                    ? foregroundTranslationAR
                    : foregroundTranslation,
                // this vote offset is used for animating some elements
                // (like the bags) on mobile
                '--vote-offset': voteOffset,
              }}
            >
              <ChoiceBox
                side="left"
                pollOption={leftItem}
                votePercent={leftVoteNumber}
                submittedState={submittedState}
                checkedItem={checkedItem}
                setCheckedItem={setCheckedItem}
                choicePercentOffset={
                  locale === 'ar' ? choicePercentOffsetAR : choicePercentOffset
                }
                translateBags={translateBags}
                mobileEyebrowText={
                  <p className={styles.mobileEyebrowText}>{eyebrow}</p>
                }
              />
              <div className={styles.choiceSeparator}>
                {versus && (
                  <span className={styles.versus}>
                    <span>{versus}</span>
                  </span>
                )}
              </div>
              <ChoiceBox
                side="right"
                pollOption={rightItem}
                votePercent={rightVoteNumber}
                submittedState={submittedState}
                checkedItem={checkedItem}
                setCheckedItem={setCheckedItem}
                choicePercentOffset={
                  locale === 'ar' ? choicePercentOffsetAR : choicePercentOffset
                }
                translateBags={translateBags}
              />
            </m.div>
          </fieldset>
        </LazyMotion>
      </div>

      <div
        className={styles.descriptionArea}
        aria-live="assertive"
        aria-atomic="true"
        role="status"
      >
        {bottomContent}
      </div>
    </Section>
  );
}

async function submitVote(
  moduleId: number,
  voteId: number,
  locale: string | undefined
) {
  const VOTING_API_ENDPOINT_DEV = `https://dev-voting.wonderful.com/v1`;
  const VOTING_API_ENDPOINT_PROD = `https://voting.wonderful.com/v1`;

  let apiEndPoint = VOTING_API_ENDPOINT_DEV;
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
    apiEndPoint = VOTING_API_ENDPOINT_PROD;
  }

  try {
    const response = await fetch(
      `${apiEndPoint}/${locale}/poll/${moduleId}/vote/${voteId}/`,
      //`http://localhost:8080/put.json`,
      {
        //method: 'GET',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          access_token: config.pollApiKey,
        },
      }
    );

    const responseJson: {
      id: number;
      votes: number;
      options: [
        {
          id: number;
          votes: number;
        },
        {
          id: number;
          votes: number;
        }
      ];
    } = await response.json();
    // comparing results based on returned api order

    const [leftResponse, rightResponse] =
      responseJson.options[0].id < responseJson.options[1].id
        ? [responseJson.options[0], responseJson.options[1]]
        : [responseJson.options[1], responseJson.options[0]];

    const totalVotes = leftResponse.votes + rightResponse.votes;
    return {
      percentages: {
        left: (leftResponse.votes / totalVotes) * 100,
        right: (rightResponse.votes / totalVotes) * 100,
      },
    };
  } catch (error) {
    // handle errors after vote submit
    console.error('[Vote]', error);
    return {
      percentages: {
        right: 50,
        left: 100 - 50,
      },
    };
  }
}

type AnimationState = 'START' | 'LOADING' | 'CANCELLED' | 'COMPLETE';

// The vote loading animation (with the bars moving back and forth)
// involves a bunch of mutable state, so we just wrap it all up in a class
class VoteLoadingAnimationRunner {
  private motionValue: MotionValue<number>;
  private state: AnimationState = 'START';
  private onAnimationCompleteListeners = new Array<{
    resolve: () => void;
    reject: () => void;
  }>();
  // once finalVoteOffset is set, it shouldn't be set back to null
  private finalVoteOffset: number | null = null;

  constructor(motionValue: MotionValue<number>) {
    this.motionValue = motionValue;
  }

  async runLoadingAnimation() {
    if (this.state !== 'START') return;
    this.state = 'LOADING';

    let numSwings = 0;
    while (this.finalVoteOffset === null || numSwings < 3) {
      if (this.state !== 'LOADING') return;
      await this.animateOffsetSwing();
      numSwings++;
    }

    if (this.state !== 'LOADING') return;

    const finalVoteOffsetSign = Math.sign(this.finalVoteOffset);
    if (Math.sign(this.motionValue.get()) === finalVoteOffsetSign) {
      await this.animateOffsetSwing();
    }

    if (this.state !== 'LOADING') return;
    const duration = getRandomTimeOffset();
    const finalOffset = this.finalVoteOffset;
    await new Promise<void>(resolve => {
      animate(this.motionValue, finalOffset, {
        duration,
        onComplete: resolve,
        onStop: resolve,
      });
    });

    if (this.state !== 'LOADING') return;

    this.state = 'COMPLETE';
    this.onAnimationCompleteListeners.forEach(({ resolve }) => resolve());
  }

  async animateOffsetSwing() {
    // come up with a new place to go within the 15-40% each way range
    let newOffset = getRandomPercentageOffset();
    // go to the other side as before
    if (this.motionValue.get() > 0) {
      newOffset *= -1;
    }
    const duration = getRandomTimeOffset();
    await new Promise<void>(resolve => {
      animate(this.motionValue, newOffset, {
        duration,
        easings: ['easeInOut'],
        onComplete: resolve,
        onStop: resolve,
      });
    });
  }

  finishLoadingAnimation(finalVoteOffset: number) {
    if (this.state === 'COMPLETE') return Promise.resolve();
    if (this.state === 'CANCELLED') return Promise.reject();

    this.finalVoteOffset = finalVoteOffset;
    return new Promise<void>((resolve, reject) => {
      this.onAnimationCompleteListeners.push({ resolve, reject });
    });
  }

  destroy() {
    this.state = 'CANCELLED';
    this.onAnimationCompleteListeners.forEach(({ reject }) => reject());
    this.onAnimationCompleteListeners = [];
    this.motionValue.stop();
  }
}

function useVoteLoadingAnimationRunner(motionValue: MotionValue<number>) {
  // assigning to a ref during a render once is considered safe
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  const ref = useRef<VoteLoadingAnimationRunner | null>(null);
  if (ref.current === null) {
    ref.current = new VoteLoadingAnimationRunner(motionValue);
  }
  return ref.current;
}

function getRandomPercentageOffset() {
  // come up with a new place for the bar to animate to
  // within the 15-40% each way range
  return Math.floor(Math.random() * 25) + 15;
}

function getRandomTimeOffset() {
  // come up with an amount of time for the bar to animate to its next location
  // within the 0.75-1.25s time range
  return Math.random() * 0.75 + 0.5;
}

interface ChoiceBoxProps {
  side: 'left' | 'right';
  pollOption: PollOption;
  votePercent: MotionValue;

  mobileEyebrowText?: React.ReactNode;
  checkedItem: number | null;
  setCheckedItem: Dispatch<SetStateAction<number | null>>;
  choicePercentOffset: MotionValue<string> | MotionValue<number>;
  submittedState: SubmittedState;
  translateBags: MotionValue<string> | MotionValue<number>;
}

function ChoiceBox(props: ChoiceBoxProps) {
  return (
    <div
      className={`${styles.choiceBox} ${
        props.side === 'left' ? styles.left : styles.right
      }`}
    >
      <div className={styles.choiceAndCheckbox}>
        {props.mobileEyebrowText}
        <label
          aria-hidden="true"
          className={styles.checkboxLabel}
          htmlFor={props.pollOption?.name}
        >
          {props.pollOption?.name}
        </label>
        <div
          className={clsx(
            styles.bigCheckboxWrapper,
            props.side === 'left' ? styles.left : styles.right
          )}
        >
          <button
            id={props.pollOption?.name}
            className={styles.bigCheckbox}
            role="checkbox"
            aria-label={`Vote for ${props.pollOption?.name}`}
            aria-checked={props.checkedItem === props.pollOption?.id}
            onClick={() => {
              props.setCheckedItem(prevCheckedItem => {
                if (prevCheckedItem === props.pollOption?.id) {
                  return null;
                } else {
                  return props.pollOption?.id;
                }
              });
            }}
          />
        </div>
      </div>
      <LazyMotion features={domAnimation}>
        <m.div
          className={styles.choicePercent}
          style={{
            // @ts-ignore
            '--translate-result': props.choicePercentOffset,
          }}
        >
          <p className={styles.choicePercentLabel}>
            {props.pollOption?.name}
            {props.submittedState.type === 'VOTED' &&
              props.submittedState.result === props.pollOption?.id &&
              ' wins'}
          </p>
          <p className={styles.choicePercentNumber}>
            <MotionValueText value={props.votePercent} />
            <span className={styles.choicePercentSign}>%</span>
          </p>
        </m.div>
        <m.div
          className={styles.choiceImageContainer}
          layout
          style={{
            // @ts-ignore
            '--translate-bags': props.translateBags,
          }}
        >
          {props.pollOption?.image?.src && (
            <BuilderImage
              imageSrc={props.pollOption?.image?.src}
              alt={props.pollOption?.image?.altText || ''}
              classes={styles.productImage}
              mobileWidth={`200px`}
              tabletWidth={`300px`}
            />
          )}
        </m.div>
      </LazyMotion>
    </div>
  );
}

// a simple component that renders the content of a MotionValue into a <span>
function MotionValueText(props: { value: MotionValue }) {
  const [value, setValue] = useState(props.value.get());

  useEffect(() => {
    return props.value.onChange((val: unknown) => {
      setValue(String(val));
    });
  }, [props.value]);
  return <span>{value}</span>;
}

const loadingMessages = [
  'Tabulating taste and flavor profiles',
  'Crackin’ shells and counting nuts',
  'Roasting stats with a dash of flavor',
  'Shelling out for final calculation',
];

function LoadingSpinner() {
  const progress = useMotionValue(0);
  const { t } = useTranslation('common');
  useEffect(() => {
    let isCancelled = false;
    let nextTimeout: NodeJS.Timeout | undefined;
    const animateToNext = () => {
      if (isCancelled) return;
      animate(progress, progress.get() + 1, {
        duration: 0.3,
        onComplete() {
          nextTimeout = setTimeout(animateToNext, 700);
        },
      });
    };
    nextTimeout = setTimeout(animateToNext, 700);

    return () => {
      isCancelled = true;
      progress.stop();
      if (nextTimeout) clearTimeout(nextTimeout);
    };
  }, [progress]);
  return (
    <div aria-label="Calculating results">
      <div className={styles.loadingSpinner} aria-hidden>
        {loadingMessages.map((message, i) => (
          <LoadingSpinnerMessage
            key={message}
            message={t(message)}
            index={i}
            progress={progress}
          />
        ))}
      </div>
    </div>
  );
}

function LoadingSpinnerMessage(props: {
  message: string;
  index: number;
  progress: MotionValue<number>;
}) {
  // on a scale from 0 to loadingMessages.length,
  // where is this message positioned?
  const placement = useTransform(
    props.progress,
    progress =>
      (progress + loadingMessages.length - props.index) % loadingMessages.length
  );

  const opacity = useTransform(placement, p => {
    if (p >= 3 && p <= loadingMessages.length - 1) {
      // we're below the visible area
      return 0;
    } else if (p > loadingMessages.length - 1) {
      // we're above the first stopping point, fading in
      return (p - (loadingMessages.length - 1)) / 2;
    } else if (p <= 1) {
      // we're at or below the first stopping point, fading in
      return p / 2 + 0.5;
    } else {
      // (1 < p < 3)
      // we're below the second stopping point, fading out
      return 1.5 - p / 2;
    }
  });

  const translateY = useTransform(placement, p => {
    let result: string | number;
    if (p > loadingMessages.length - 1) {
      // if placement > loadingMessages.length - 1, we actually position it
      // under the placement=0 position
      result = p - (loadingMessages.length - 1);
    } else {
      result = p + 1;
    }
    // at this point, result should be the position of the item in the column,
    // where 0 means it's invisible at the top, and 4 or more means it's invisible
    // at the bottom.

    result -= props.index;
    return `${result * 100}%`;
  });

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className={styles.loadingSpinnerMessage}
        style={{ opacity, translateY }}
      >
        {props.message}
      </m.div>
    </LazyMotion>
  );
}
